import { ImagesLoaded } from "../interfaces/images";

const imagesLoaded = require("imagesloaded");

export const delayMs = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const onImagesLoaded = (elem: Element) => {
  const imgLoad = imagesLoaded(elem);

  return new Promise((resolve, reject) => {
    imgLoad.on("done", (instance: ImagesLoaded) => {
      resolve(instance?.images?.length);
    });

    imgLoad.on("fail", (instance: any) => {
      reject(instance);
    });

    // imgLoad.on("progress", (instance: ImagesLoaded, image: any) => {
    //   console.log({ instance, image })
    //   if(instance.progressedCount > 10) resolve(instance?.images?.length);
    // });
  });
};

let throttlePause = false;

export const throttle = (callback: Function, time: number) => {
  if (throttlePause) return;
  throttlePause = true;
  setTimeout(() => {
    callback();
    throttlePause = false;
  }, time);
};

export function getPositionX(event: any) {
  return event.type.includes("mouse") ? event.pageX : event.touches[0].clientX;
}

export function getElementDimensions(ref: any) {
  const width = ref.current.clientWidth;
  const height = ref.current.clientHeight;
  return { width, height };
}

export const getWorkTypeName = (type: string) => {
  if(type === 'social-assets') return 'social';
  return type;
}
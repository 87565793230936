import { useCallback } from "react";
import { useSocialAssetsWork } from "./useSocialAssetsWork";
import { Container, HeaderContainer, MosaicsContainer, WorkName } from "./styles";
import { Helmet } from "react-helmet";
import { actions } from "../../redux/loading";
import { MenuOptionEnum } from "../../components/Navbar/types";
import { WorkHeader } from "../../components/WorkHeader/WorkHeader";
import OtherProjects from "../../components/OtherProjects/OtherProjects";
import { CustomNavbar } from "../../components/WorkNavbar/WorkNavbar";
import Reveal from "../../components/Reveal/Reveal";
import ShareButton from "../../components/ShareButton/ShareButton";
import Mosaics from "../../components/Mosaics/Mosaics";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { delayMs } from "../../utils/utils";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
import { GoBackButton } from "../../components/GoBack/GoBack";
import { useResponsive } from "../../hooks/useResponsive";

const INITIAL_DELAY = 150;

export const SocialAssetsWork = () => {
  const { isMobile } = useResponsive();
  const { containerRef, work, onOptionClick } = useSocialAssetsWork();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = async () => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    navigate("/work", { replace: true });
  };

  const renderMosaic = useCallback(() => {
    if (work?.mosaico && work?.mosaico?.length > 0) {
      return (
        <Reveal>
          <MosaicsContainer>
            <Mosaics images={work?.mosaico} cols={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }} />
          </MosaicsContainer>
        </Reveal>
      );
    }
    return null;
  }, [work?.mosaico]);

  const title = `VRØDAS | Social Assets - ${work?.nombre || ""}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={work?.descripcion_en || ""} />
        <meta name="thumbnail" content={work?.thumb} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="music video" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={work?.thumb} />
        <meta property="og:description" content={work?.descripcion_en || ""} />
        <meta property="og:site_name" content="VRØDAS" />
      </Helmet>
      <ShareButton title={title} text={work?.descripcion_en || ""} url={window.location.href} />
      <CustomNavbar animated={false} fixed actualOption={MenuOptionEnum.HomeWorks} onOptionClick={onOptionClick} />
      <Container ref={containerRef} className="social-assets-work">
        <HeaderContainer>
          <Reveal delay={INITIAL_DELAY + 250}>
            <WorkName>{work?.nombre}</WorkName>
          </Reveal>
          {!isMobile && (
            <Reveal delay={INITIAL_DELAY}>
              <GoBackButton onClick={handleBack}>GO BACK</GoBackButton>
            </Reveal>
          )}
        </HeaderContainer>
        <WorkHeader description={work?.descripcion_en || ""} items={[]} hideBackButton columnCount={3} />
        {renderMosaic()}
        <OtherProjects works={work?.otros_proyectos || []} />
      </Container>
    </>
  );
};

export default SocialAssetsWork;

import { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  MosaicsContainer,
  MosaicsColumn,
  MosaicImage,
  MosaicCaptionContainer,
  MosaicCaption,
  MosaicContainer,
} from "./styles";
import { MosaicsProps } from "./types";
import { ThemeContext } from "styled-components";

const DEFAULT_COLS = { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 };

const Mosaics = ({ id = "mosaic-container", images, cols }: MosaicsProps) => {
  const [numberOfColumns, setNumberOfColumns] = useState(1);
  const { breakpoints } = useContext(ThemeContext);

  const getNumberOfColumns = useCallback(
    (width: number) => {
      if (width < breakpoints.xs) return cols?.xs ?? DEFAULT_COLS.xs;
      if (width < breakpoints.sm) return cols?.sm ?? DEFAULT_COLS.sm;
      if (width < breakpoints.md) return cols?.md ?? DEFAULT_COLS.md;
      if (width < breakpoints.lg) return cols?.lg ?? DEFAULT_COLS.lg;
      return cols?.xl ?? DEFAULT_COLS.xl;
    },
    [cols, breakpoints]
  );

  const renderColumn = useCallback(
    (columnIndex: number) => {
      return images
        .filter((img) => img.imagen || img.embed_code_video)
        .filter((img, index) => index % numberOfColumns === columnIndex)
        .map((image, index) => {
          if (image.embed_code_video) {
            return (
              <MosaicContainer>
                <div
                  style={{ display: 'flex'}}
                  dangerouslySetInnerHTML={{
                    __html: image.embed_code_video,
                  }}
                />
                {image.caption && (
                  <MosaicCaptionContainer>
                    <MosaicCaption>{image.caption}</MosaicCaption>
                  </MosaicCaptionContainer>
                )}
              </MosaicContainer>
            );
          }

          return (
            <MosaicContainer>
              <MosaicImage key={`${columnIndex}-${index}`} loading="lazy" src={image.imagen} alt={image.descripcion} />
              {image.caption && (
                <MosaicCaptionContainer>
                  <MosaicCaption>{image.caption}</MosaicCaption>
                </MosaicCaptionContainer>
              )}
            </MosaicContainer>
          );
        });
    },
    [images, numberOfColumns]
  );

  const calculateIframeSize = () => {
    const container = document.querySelector(`#${id}`);
    const numberOfColumns = getNumberOfColumns(window.innerWidth);
    setNumberOfColumns(numberOfColumns);

    if (container) {
      const columnWidth = container.clientWidth / numberOfColumns - 6;
      document.querySelectorAll(`#${id} iframe`).forEach((iframe) => {
        const width = iframe.getAttribute("width");
        const height = iframe.getAttribute("height");
        if (width && height) {
          const aspectRatio = getNumber(height) / getNumber(width);
          iframe.setAttribute("width", `${columnWidth}px`);
          iframe.setAttribute("height", `${columnWidth * aspectRatio}px`);
        }
      });
    }
  };

  useLayoutEffect(() => {
    calculateIframeSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, cols]);

  useEffect(() => {
    window.addEventListener("resize", calculateIframeSize);
    return () => {
      window.removeEventListener("resize", calculateIframeSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, cols]);

  return (
    <MosaicsContainer id={id} cols={cols || DEFAULT_COLS}>
      {Array.from({ length: numberOfColumns }, (_, i) => (
        <MosaicsColumn>{renderColumn(i)}</MosaicsColumn>
      ))}
    </MosaicsContainer>
  );
};

export default Mosaics;

const getNumber = (str: string) => {
  const strWithoutPx = str.split('px')[0];
  return Number(strWithoutPx)
}
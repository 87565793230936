import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import Home from "../pages/Home/Home";
import Works from "../pages/Works/Works";
import CaseWork from "../pages/CaseWork/CaseWork";
import VideoWork from "../pages/VideoWork/VideoWork";
import DesignWork from "../pages/DesignWork/DesignWork";
import SocialWork from "../pages/SocialWork/SocialWork";
import SocialAssetsWork from "../pages/SocialAssetsWork/SocialAssetsWork";

import { useEffect } from "react";
import { pageview } from "../utils/gtag";
import FacebookPixel from "../components/FacebookPixel/FacebookPixel";

const AppRouter = () => {
  return (
    <Router>
      <Gtag />
      <FacebookPixel />
      <Routes>
        <Route>
          <Route path="/" element={<Home />} />
          <Route path="/work" element={<Works />} />
          <Route path="/work/:filter" element={<Works />} />
          <Route path="/work/video/:id" element={<VideoWork />} />
          <Route path="/work/design/:id" element={<DesignWork />} />
          <Route path="/work/case/:id" element={<CaseWork />} />
          <Route path="/work/social/:id" element={<SocialWork />} />
          <Route path="/work/social-assets/:id" element={<SocialAssetsWork />} />
          <Route path="/:section" element={<Home />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </Router>
  );
}

const Gtag = () => {
  const location = useLocation();

  useEffect(() => {
    pageview(location.pathname)
  }, [location])

  return null;
}

export default AppRouter;
import styled, { css } from "styled-components";

export const Header = styled.div`
  width: 100%;
  text-align: left;

  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 80px ${spacing.xl}px 80px ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 64px ${spacing.lg}px 80px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 48px ${spacing.md}px 80px ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 32px ${spacing.sm}px 60px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 32px ${spacing.xs}px 40px ${spacing.xs}px;
    }

  `}
`;

export const Row = styled.div<{ marginBottom: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: ${({ marginBottom }) => marginBottom ? 64 : 16}px;

  & > div:first-child {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 16px;
  }
`

export const Artist = styled.h3`
  font-size: 32px;
  line-height: 30px;
  margin: 0;
  width: 50%;
  font-weight: 600;
  text-transform: uppercase;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.lg}px) {
      width: 70%;
      font-size: 28px;
      line-height: 25px;
    }

    @media (max-width: ${breakpoints.md}px) {
      width: 100%;
      font-size: 24px;
      line-height: 20px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      width: 100%;
      font-size: 16px;
      line-height: 14px;
    }
  `}
`;

export const WorkName = styled.h1`
  font-size: 96px;
  width: 100%;
  font-family: "VrodasFontTitle";
  line-height: 96px;
  margin: 0;
  font-weight: 400;
  letter-spacing: -1px;
  text-transform: lowercase;
  margin-bottom: 48px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme: { breakpoints, palette } }) => css`
    color: ${palette.primary};

    @media (max-width: ${breakpoints.lg}px) {
      width: 70%;
      font-size: 84px;
      line-height: 84px;
      margin-bottom: 40px;
    }

    @media (max-width: ${breakpoints.md}px) {
      width: 100%;
      font-size: 56px;
      line-height: 56px;
      margin-bottom: 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      width: 100%;
      font-size: 40px;
      line-height: 38px;
    }
  `}
`;

export const DetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 26px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  }
`;

export const InfoContainer = styled.div<{ breakColumns: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  ${({ breakColumns }) => breakColumns && css`
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
      grid-template-columns: 1fr;
    }
  `}
`;

export const Side = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
`

export const Label = styled.h6`
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Text = styled(Label)`
  font-weight: 400;
  text-transform: none;
  white-space: pre-line;
  font-family: "Open Sans";
`;

export const Description = styled.p<{ columnCount?: number }>`
  font-family: "Open Sans";
  font-size: 18px;
  margin: 0;
  font-weight: 400;
  color: #646464;
  column-count: ${({ columnCount }) => columnCount || 1};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 16px;
    column-count: 1;
  }
`;

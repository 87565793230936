import styled, { css } from "styled-components";
import { NAVBAR_HEIGHT, VIDEO_ASPECT_RELATION } from "../../utils/constants";

export const PageContainer = styled.div<{ show: boolean }>`
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

export const Container = styled.div`
  background-color: white;
  margin-top: ${NAVBAR_HEIGHT}px;
  height: ${`calc(100vh - ${NAVBAR_HEIGHT}px)`};
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;

export const WorkContent = styled.div`
  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 0px ${spacing.xl}px ;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 0px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 0px ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 0px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 0px ${spacing.xs}px;
    }
  `}
`

export const VideoContainer = styled.div<{ width: number }>`
  width: 100%;
  margin-top: 50px;

  & > p {
    display: none;
  }

  ${({ theme, width }) => css`
    & > iframe {
      width: ${width - 2 * theme.spacing.xl}px;
      height: ${(width - 2 * theme.spacing.xl) / VIDEO_ASPECT_RELATION}px;
    }

    @media (max-width: ${theme.breakpoints.lg}px) {
      & > iframe {
        width: ${width - 2 * theme.spacing.lg}px;
        height: ${(width - 2 * theme.spacing.lg) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.md}px) {
      & > iframe {
        width: ${width - 2 * theme.spacing.md}px;
        height: ${(width - 2 * theme.spacing.md) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.sm}px) {
      & > iframe {
        width: ${width - 2 * theme.spacing.sm}px;
        height: ${(width - 2 * theme.spacing.sm) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.xs}px) {
      & > iframe {
        margin-left: 0px;
        width: ${width}px;
        height: ${width / VIDEO_ASPECT_RELATION}px;
      }
    }
  `}
`;

export const FrontImage = styled.img`
  width: 100%;
  margin-bottom: 48px;
`;

export const Description = styled.div`
  //width: 67%;
  width: 100%;
  margin: 16px 0 70px 0;
  //margin-left: 33%;
  margin-left: 0;
  column-count: 2;
  column-gap: 16px;
  font-family: "Open Sans";
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: #646464;

  ${({ theme: { breakpoints } }) => css`
      @media (max-width: ${breakpoints.lg}px) {
        font-size: 16px;
      }
      @media (max-width: ${breakpoints.md}px) {
        width: 100%;
        margin-left: 0;
      }

      @media (max-width: ${breakpoints.sm}px) {
        column-count: 1;
        font-size: 14px;
      }
  `}
`
import axios from "axios";
import {
  CaseWorkType,
  Category,
  DesignWorkType,
  SocialAssetWorkType,
  SocialWorkType,
  VideoWorkType,
  Work,
} from "../interfaces/work";
import { Home } from "../interfaces/home";
// import { getSocialAssetsWorkMock } from "../mocks/getSocialAssetsWork";
// import { getSocialWorkMock } from "../mocks/getSocialWork";
// import { getAllWorksMock } from "../mocks/getAllWorks";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getHome = () => {
  return client.get<Home>("/home-detail");
};

export const getHomeWorks = () => {
  return client.get<Work[]>("/home/works");
};

export const getWorks = (categories: string): Promise<{ data: Work[] }> => {
  return client.get<Work[]>("works/all", { params: { categories } });
  // return getAllWorksMock();
};

export const getCategories = () => {
  return client.get<Category[]>("categories");
};

export const getVideoWork = (path: string) => {
  return client.get<VideoWorkType>(`/works/videos/${path}`);
};

export const getDesignWork = (path: string) => {
  return client.get<DesignWorkType>(`/works/designs/${path}`);
};

export const getCaseWork = (path: string) => {
  return client.get<CaseWorkType>(`/works/cases/${path}`);
};

export const getSocialWork = (path: string) => {
  return client.get<SocialWorkType>(`/works/socials/${path}`);
  // return getSocialWorkMock();
};

export const getSocialAssetsWork = (path: string) => {
  return client.get<SocialAssetWorkType>(`/works/social_assets/${path}`);
  // return getSocialAssetsWorkMock();
};

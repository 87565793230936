import styled from "styled-components";
import { Cols } from "./types";

export const MosaicsContainer = styled.div<{ cols: Cols }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ cols }) => `repeat(${cols.xl}, calc(${100 / cols.xl}% - 6px))`};
  grid-column-gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: ${({ cols }) => `repeat(${cols.lg}, calc(${100 / cols.lg}% - 6px))`};
    grid-column-gap: ${({ cols }) => (cols.lg === 1 ? 0 : 12)}px;
    grid-row-gap: ${({ cols }) => (cols.lg === 1 ? 12 : 10)}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: ${({ cols }) => `repeat(${cols.md}, calc(${100 / cols.md}% - 6px))`};
    grid-column-gap: ${({ cols }) => (cols.md === 1 ? 0 : 12)}px;
    grid-row-gap: ${({ cols }) => (cols.md === 1 ? 12 : 10)}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: ${({ cols }) => `repeat(${cols.sm}, calc(${100 / cols.sm}% - 6px))`};
    grid-column-gap: ${({ cols }) => (cols.sm === 1 ? 0 : 12)}px;
    grid-row-gap: ${({ cols }) => (cols.sm === 1 ? 12 : 10)}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    grid-template-columns: ${({ cols }) => `repeat(${cols.xs}, calc(${100 / cols.xs}% - 6px))`};
    grid-column-gap: ${({ cols }) => (cols.xs === 1 ? 0 : 12)}px;
    grid-row-gap: ${({ cols }) => (cols.xs === 1 ? 12 : 10)}px;
  }
`;

export const MosaicsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;

  /* @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 6px;
  } */
`;

export const MosaicContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

export const MosaicImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const MosaicCaptionContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.palette.dark};
`;

export const MosaicCaption = styled.p`
  font-family: "Open Sans";
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  text-align: left;
  color: #fff;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 14px;
  }
`;

import styled, { css } from "styled-components";
import { NAVBAR_HEIGHT } from "../../utils/constants";

export const Container = styled.div`
  background-color: white;
  margin-top: ${NAVBAR_HEIGHT}px;
  height: ${`calc(100vh - ${NAVBAR_HEIGHT}px)`};
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;

export const WorkContent = styled.div`
  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 0px ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 0px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 0px ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 0px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 0px ${spacing.xs}px;
    }
  `}
`;

export const FrontImage = styled.img`
  width: 100%;
`;

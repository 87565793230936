import styled, { css } from "styled-components";
import { NAVBAR_HEIGHT } from "../../utils/constants";

export const Container = styled.div`
  background-color: white;
  margin-top: ${NAVBAR_HEIGHT}px;
  height: ${`calc(100vh - ${NAVBAR_HEIGHT}px)`};
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;

export const WorkContent = styled.div`
  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 0px ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 0px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 0px ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 0px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 0px ${spacing.xs}px;
    }
  `}
`;

export const FrontImage = styled.img`
  width: 100%;
`;

export const MosaicsContainer = styled.div`
  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 0px ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 0px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 0px ${spacing.md}px;
      grid-template-columns: 1fr;
      grid-row-gap: 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 0px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 0px ${spacing.xs}px;
      /* padding: 0px; */
    }
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;

  & > div:first-child {
    width: 100%;
  }

  width: 100%;
  text-align: left;

  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 80px ${spacing.xl}px 0px ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 64px ${spacing.lg}px 0px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 48px ${spacing.md}px 0px ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 32px ${spacing.sm}px 0px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 32px ${spacing.xs}px 0px ${spacing.xs}px;
    }

  `}

`;

export const WorkName = styled.h1`
  font-size: 96px;
  width: 100%;
  font-family: "VrodasFontTitle";
  line-height: 96px;
  margin: 0;
  font-weight: 400;
  letter-spacing: -1px;
  text-transform: lowercase;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme: { breakpoints, palette } }) => css`
    color: ${palette.primary};

    @media (max-width: ${breakpoints.lg}px) {
      width: 70%;
      font-size: 84px;
      line-height: 84px;
    }

    @media (max-width: ${breakpoints.md}px) {
      width: 100%;
      font-size: 56px;
      line-height: 56px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      width: 100%;
      font-size: 40px;
      line-height: 38px;
    }
  `}
`;
